import React from "react";
import "./style.css";
import moment from "moment";

const TimePickerComponent = ({
  therapistIndex,
  dayIndex,
  handleShiftChange,
  name,
  value,
}) => {
  const generateHourOptions = () => {
    const hours = [];
    for (let i = 10; i <= 29; i++) {
      hours.push(
        <option key={i} value={i % 24}>
          {(i % 24).toString().padStart(2, "0")}
        </option>
      );
    }
    return hours;
  };

  const generateMinuteOptions = () => {
    const minutes = Array.from({ length: 60 }, (_, index) => index).map(
      (minute) => (
        <option
          key={minute}
          value={minute}
          disabled={Number(value.hour()) === 5 && minute !== 0}
        >
          {minute.toString().padStart(2, "0")}
        </option>
      )
    );
    return minutes;
  };

  const handleChangeTime = (newValue, type) => {
    const hour = value.hour();
    const minute = value.minute();
    let time;
    if (type === "hour") {
      if (Number(value) === 5) {
        time = moment("05:00", "HH:mm");
      } else time = moment(`${newValue}:${minute}`, "HH:mm");
    } else {
      time = moment(`${hour}:${newValue}`, "HH:mm");
    }
    handleShiftChange(therapistIndex, dayIndex, name, time);
  };

  return (
    <div className="select-time">
      <select
        className="select-time"
        value={value.hour()}
        onChange={(e) => handleChangeTime(e.target.value, "hour")}
      >
        {generateHourOptions()}
      </select>
      :
      <select
        className="select-time"
        value={value.minute()}
        onChange={(e) => handleChangeTime(e.target.value, "minute")}
      >
        {generateMinuteOptions()}
      </select>
    </div>
  );
};

export default TimePickerComponent;
