import React from 'react';
import useTherapistNomination from '../../features/therapistNomination';
import PopupComponent from '../../layouts/Popup';
import { Button, Grid, Switch, TextField, Typography } from '@mui/material';

const PopupTherapistNomination = ({
  therapist,
  isOpen,
  onClose,
  onSubmit = () => { },
}) => {
  const onSave = () => {
    onClose();
    onSubmit();
  };

  const {
    therapistNominations,
    errors,
    handleChange,
    handleSave,
    handleToggle,
  } = useTherapistNomination(therapist.id, onSave);

  return (
    <PopupComponent open={isOpen} onClose={onClose}>
      <div style={{ textAlign: 'left', borderBottom: '1px solid' }}>{therapist.name}</div>
      {therapistNominations.map((nomination, index) => (
        <div key={nomination.nomination_id} style={{ marginTop: 20, display: "flex", alignItems: "center", gap: 5 }}>
          <div>{nomination.name}</div>
          <TextField
            style={{ width: 180 }}
            label="お客様へ請求する金額"
            name="nomination_cost"
            value={nomination.nomination_cost}
            onChange={(e) => handleChange(e, nomination.nomination_id)}
            required
            type="number"
            onKeyDown={(e) => {
              if (
                e.key === "e" ||
                e.key === "E" ||
                e.key === "-" ||
                e.key === "+" ||
                e.key === "."
              ) {
                e.preventDefault();
              }
            }}
            error={!!errors?.[index]?.nomination_cost}
            helperText={errors?.[index]?.nomination_cost || ""}
          />
          <Grid item sx={{ display: "flex", marginTop: "20px" }}>
            <Switch checked={nomination.therapist_amount !== null} onChange={(e) => handleToggle(e, nomination.nomination_id)} />
            {nomination.therapist_amount !== null ? (
              <TextField
                fullWidth
                label="本指名料"
                name="therapist_amount"
                value={nomination.therapist_amount || ""}
                onChange={(e) => handleChange(e, nomination.nomination_id)}
                required
                type="number"
                onKeyDown={(e) => {
                  if (
                    e.key === "e" ||
                    e.key === "E" ||
                    e.key === "-" ||
                    e.key === "+" ||
                    e.key === "."
                  ) {
                    e.preventDefault();
                  }
                }}
                sx={{ marginLeft: 2 }}
                error={!!errors?.[index]?.therapist_amount}
                helperText={errors?.[index]?.therapist_amount || ""}
              />
            ) : <Typography>1,000円～本指名５本ごとに500円アップ</Typography>}
          </Grid>
        </div>
      ))}
      <Button
        type="button"
        fullWidth
        variant="outlined"
        color="success"
        style={{ marginTop: 20 }}
        onClick={handleSave}
      >
        保存
      </Button>
    </PopupComponent>
  );
};

export default PopupTherapistNomination;
